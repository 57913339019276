import { render, staticRenderFns } from "./Avize_dialog.vue?vue&type=template&id=15d2c558&scoped=true&"
import script from "./Avize_dialog.vue?vue&type=script&lang=js&"
export * from "./Avize_dialog.vue?vue&type=script&lang=js&"
import style0 from "./Avize_dialog.vue?vue&type=style&index=0&id=15d2c558&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15d2c558",
  null
  
)

export default component.exports