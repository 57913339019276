<template>
  <base-page>
    <!-- <el-dialog :title="'Fisa produs ' + selectedObject.Nume " :visible.sync="showPopup" id="popup_dialog" :fullscreen="true" class="my-dialog-class" append-to-body> -->
    <el-card style="margin: 5px 0px 5px 0px">
      <el-collapse accordion style="border: none">
        <el-collapse-item name="1" style="border: none">
          <template slot="title">
            <div slot="header" class="clearfix">
              <titlu-pagina Titlu="Fisa produs" @on_add_clicked="show_dialog()" :AdaugaVisible="false" />
            </div>
          </template>
          <div class="filtre">
            <el-form @submit="refresh_info()" label-position="top">
              <el-row :gutter="20">
                <el-col>
                  <el-form-item label="Produs">
                    <el-select class="full-width" v-model="Filters.IdProdus" filterable>
                      <el-option v-for="item in Info.produse" :key="'produse' + item.Id" :label="item.Nume"
                        :value="item.Id" />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item label="Gestiune">
                    <el-select class="full-width" v-model="Filters.IdGestiune" filterable>
                      <el-option v-for="item in Info.gestiuni" :key="'gestiuni' + item.Id" :label="item.Nume"
                        :value="item.Id" />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item label='Interval'>
                    <el-date-picker class="full-width" v-model="Filters.Data" type="daterange" range-separator="-"
                      start-placeholder="Data inceput" end-placeholder="Data sfarsit"
                      :picker-options="datePickerOptions" format="dd/MM/yyyy" value-format="yyyy-MM-dd">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item label="Actiune">
                    <el-select class="full-width" v-model="Filters.TipMiscare" filterable>
                      <el-option label="Toate" value="-1"></el-option>
                      <el-option label="Intrare" value="Intrare"></el-option>
                      <el-option label="Iesire" value="Iesire"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col>
                  <el-form-item label="Client">
                    <el-select class="full-width" v-model="Filters.IdClient" filterable>
                      <el-option label="Toti" value="-1"></el-option>
                      <el-option v-for="item in Info.clienti_facturi"
                        :key="'clienti_facturi' + item.IdClient + item.NumeClient" :label="item.NumeClient"
                        :value="item.IdClient" />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col style="width: 100%">
                  <div class="footer-form">
                    <el-button type="primary" native-type="submit" @click="refresh_info()" size="small">Aplica</el-button>
                    <el-button type="danger" native-type="submit" @click="reset()" size="small">Reseteaza</el-button>
                    <el-button v-if="Raport.Randuri.length > 0" type="info" native-type="submit" @click="print()" size="small">Printeaza</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-card>
    <el-card style="margin: 5px 0px 5px 0px">
      <table v-if="Raport.Randuri.length > 0" cellpadding="6" class="tabel-data" style="margin-top: 6px; width: 100%">
        <thead>
          <tr>
            <th style="text-align: left;">Crt</th>
            <th style="text-align: right;">Data</th>
            <th style="text-align: center;">Actiune</th>
            <th style="text-align: center;">Cantitate</th>
            <th style="text-align: center;">P.U.</th>
            <th style="text-align: right;">Valoare</th>
            <th style="text-align: right;">Stoc Initial</th>
            <th style="text-align: right;">Stoc Final</th>
            <th style="text-align: right;">Sold Initial</th>
            <th style="text-align: right;">Sold Final</th>
            <th style="text-align: right;">Document</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, idx) in Raport.Randuri" :key="'rand-raport-' + idx" :class=" item.Tip ">

            <td style="text-align: left;">{{item.NrCrt}}</td>
            <td style="text-align: right;">{{item.Data | Data}}</td>
            <td style="text-align: center;">{{item.Tip}}</td>
            <td style="text-align: center;">{{item.Cantitate}}</td>
            <td style="text-align: center;">
              <div v-if="item.PretUnitar == 0">-</div>
              <div v-else>{{item.PretUnitar | format_money}}</div>
            </td>
            <td style="text-align: right;">
              <div v-if="item.Valoare == 0">-</div>
              <div v-else>{{item.Valoare | format_money}}</div>
            </td>
            <td style="text-align: right;">{{item.StocInitial}}</td>
            <td style="text-align: right;">{{item.StocFinal}}</td>
            <td style="text-align: right;">{{item.SoldInitial | format_money}}</td>
            <td style="text-align: right;">{{item.SoldFinal | format_money}}</td>
            <td style="text-align: right;">
              <el-tooltip content="Deschide document">
                <el-button el-button type="text" @click="decodifica(item)" style="padding: 0px 0px;">
                  {{item.Document}}
                </el-button>
              </el-tooltip>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else> Nu exista inregistrari. Modificati filtrele! </div>
     

   
      <el-row v-if="Raport.Randuri.length > 0">
        <el-col>
          <table cellpadding="10" style="margin-top: 10px; width: 40%; float: right;">
            <tr>
              <th>Stoc initial</th>
              <td style="text-align: right;"> {{ Raport.StocInitial }}</td>
            </tr>
            <tr>
              <th>Sold initial</th>
              <td style="text-align: right;"> {{ Raport.SoldInitial | format_money }} </td>
            </tr>
            <tr class="cell-data">
              <th>Stoc final</th>
              <td style="text-align: right;"> {{ Raport.StocFinal }} </td>
            </tr>
            <tr class="cell-data">
              <th>Sold final</th>
              <td style="text-align: right;"> {{ Raport.SoldFinal | format_money }} </td>
            </tr>
          </table>
        </el-col>
      </el-row>
    </el-card> 

    
    <!-- <span slot="footer" class="dialog-footer"> -->
      <!-- <el-button @click="showPopup = false"> Inchide </el-button> -->
      <!-- <el-button type="primary" @click="save"> Salveaza </el-button> -->
    <!-- </span> -->

    <Niruri-dialog ref="Nir" @save="refresh_info" />
    <Facturi-dialog ref="Factura" @save="refresh_info" />
    <Avize-dialog ref="Aviz" @save="refresh_info" />
    <Transferuri-dialog ref="Transfer" @save="refresh_info" />
    <Bonuri_consum-dialog ref="Bon consum" @save="refresh_info" />
    <Inventar-dialog ref="Inventar" @save="refresh_info" />
    <!-- </el-dialog> -->
  </base-page>


</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Niruri_dialog from "@/pages/niruri/Niruri_dialog.vue";
import Facturi_dialog from "@/pages/facturi/Facturi_dialog.vue";
import Avize_dialog from "@/pages/avize/Avize_dialog.vue";
import Transferuri_dialog from "@/pages/transferuri/Transferuri_dialog.vue";
import Inventar_dialog from "@/pages/inventar/Inventar_dialog.vue";
import Bonuri_consum_dialog from "@/pages/bonuri_consum/Bonuri_consum_dialog.vue";
import TitluPagina from "@/widgets/TitluPagina";
import moment from 'moment';

export default {
  name: "Produse_dialog",
  extends: BasePage,
  components: {
    "base-page": BasePage,
    "Niruri-dialog": Niruri_dialog,
    "Facturi-dialog": Facturi_dialog,
    "Avize-dialog": Avize_dialog,
    "Transferuri-dialog": Transferuri_dialog,
    "Inventar-dialog": Inventar_dialog,
    "Bonuri_consum-dialog": Bonuri_consum_dialog,
    "titlu-pagina": TitluPagina
  },
  data() {
    return {
      datePickerOptions: settings.datePickerOptions,
      id: -1,
      baseUrl: "",
      showPopup: false,
      Raport:{
        Randuri: [],
        SoldInitial: 0,
        SoldFinal: 0,
        StocInitial: 0,
        StocFinal:0
      },
      
      Filters: {
        IdProdus: "",
        IdClient: "-1",
        TipMiscare: "-1",
        IdGestiune: "1",
        Data: [moment().startOf('year').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      },
      Info: {
        gestiuni: [],
        produse: [],
        clienti: [],
      },
     
    };
  },
  methods: {
    // async show_me(id) {
    //   console.log('aaa')
    //   this.id = id;
    //   await this.refresh_info()
    //   this.showPopup = true;

    // },
    async get_info() {
      var response = await this.post("Rapoarte/raport_fisa/get_info_fisa");
      this.Info.gestiuni = response.gestiuni;
      this.Info.produse = response.produse;
      this.Filters.IdProdus = this.Info.produse[0].Id;
      this.Info.clienti_facturi = response.clienti_facturi;
      // this.refresh_info();
    },

    async refresh_info() {
      var result = await this.post("Rapoarte/raport_fisa/get_fisa", { OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo, Filters: this.Filters, });
      this.Raport   = result.raspuns;
    },

    decodifica(ref_tranzactie) {
      console.log(ref_tranzactie)
     
      this.$refs[ref_tranzactie.Document].show_me(ref_tranzactie.IdDocument);
    },

    reset() {
      this.Filters = {
        IdProdus: this.Info.produse[0].Id,
        IdClient: "-1",
        TipMiscare: "-1",
        IdGestiune: "1",
        Data: [moment().startOf('year').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      };
      this.refresh_info();
    },

    async print() {
      let res = await this.post('Rapoarte/Raport_fisa/print_produs', { IdProdus: this.Filters.IdProdus, Raport: this.Raport });

      let doc = window.open("", "_blank");
      doc.document.write(res.html);
    },
  },

  mounted: function () {
    this.baseUrl = settings.BASE_URL;
    this.get_info();
  },
};
</script>


<style lang="less" scoped>
.filtre {
  width: 100% !important;
  display: grid;
  grid-template-columns: repeat(2);

  .el-col {
    width: 20%;
  }


  .el-input-number {
    width: 100% !important;
  }

  .tabel-data {
    width: 300px !important;
    word-break: keep-all;

  }

}
</style>