<template>
    <el-dialog title="Inventar" :visible.sync="showPopup" :fullscreen="true" append-to-body
        :close-on-press-escape="false">
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%" :model="selectedObject"
            ref="my-form" @submit.prevent="save" v-loading="loadingVisible" status-icon size="mini">
            <el-row :gutter="15">

                <el-col :md="4">
                    <el-form-item label="Nr. Doc" prop="NrDoc">
                        <el-input class="full-width" v-model="selectedObject.NrDoc" ref="focusMe" />
                    </el-form-item>
                </el-col>
                <el-col :md="4">
                    <el-form-item label="Data" prop="Data" size="mini">
                        <div class="full-width">
                            <el-date-picker 
                            :clearable="false"    
                            v-model="selectedObject.Data" type="date" value-format="yyyy-MM-dd"
                                format="dd.MM.yyyy" />
                        </div>
                    </el-form-item>
                </el-col>
                <el-col :md="4">
                    <el-form-item label="Gestiune sursa" prop="IdGestiune">
                        <el-select size="mini" class="full-width" v-model="selectedObject.IdGestiune"
                            @change="handleSelectGestiune()" filterable>
                            <el-option v-for="item in Info.gestiuni" :key="item.Id + item.Nume" :label="item.Nume"
                                :value="item.Id"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>  

            </el-row>
            <el-row>
                <el-col :md="24">
                    <el-card shadow="hover"  v-show="selectedObject.IdGestiune !== ''">
                        <div slot="header" class="clearfix" style="padding: 0px; height: 15px;">
                            <span>Produse</span>
                            <el-button v-if="mode=='add'" size="mini" style="float: right"  @click="precompleteaza()"> Set scriptic=faptic </el-button>
                        </div>
                        <el-table 
                            :header-cell-style="{ background: '#393E46' }" 
                            :data="selectedObject.Produse" 
                            :row-class-name="tableRowClassName"
                            size="mini">
                            <el-table-column :min-width="130" prop="Nume" label="Denumire Produs"></el-table-column>
                            <el-table-column prop="CodProdus" label="Cod Produs"></el-table-column>
                            <el-table-column prop="StocScriptic" label="Stoc scriptic" align="right"> </el-table-column>
                            <el-table-column prop="StocFaptic" label="Stoc faptic" align="right">
                                <template #default="scope">
                                    <el-input-number id="StocFaptic" :min="0" v-model="scope.row.StocFaptic" size="mini" ></el-input-number>
                                </template>
                            </el-table-column>
                            <el-table-column label="Diferente inventar" align="right">
                                <template #default="scope">
                                    {{scope.row.StocFaptic - scope.row.StocScriptic}}
                                </template>
                            </el-table-column>
                        </el-table>

                    </el-card>
                </el-col>
            </el-row>
        </el-form>

        <span slot="footer" class="dialog-footer">
            <el-button @click="resetForm('my-form')"> Renunta </el-button>
            <el-button type="primary" @click="save('my-form')"> Salveaza </el-button>
        </span>

        <!-- <button @click="test_consola"> TEST SELECTED OBJECT CONSOLA</button> -->
    </el-dialog>
</template>
  
<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Dashboard from '../Dashboard.vue';

export default {
    name: "Facturi_dialog",
    extends: BasePage,
    components: {
        Dashboard
    },
    data() {
        return {
            showPopup: false,
            mode: "add",
            selectedObject: {},
            selectedObjectTemplate: {
                Data: "",
                Id: "",
                NrDoc: "",
                IdGestiune: "",
                Produse: [],
            },
            // produsTemplate: {
            //     IdProdus: "",
            //     NumeProdus: "",
            //     Cantitate: "",
            // },
            Info: {
                gestiuni: [],
                produse: [],
                NrDoc: '',
            },
            rules: {
                Id: [{ required: true, message: "Campul este obligatoriu" }],
                Data: [{ required: true, message: "Campul este obligatoriu" }],
                IdGestiune: [{ required: true, message: "Campul este obligatoriu" }],
                IdProdus: [{ required: true, message: "Campul este obligatoriu" }],
                Cantitate: [{ required: true, message: "Campul este obligatoriu" }],
            },
        };
    },
    methods: {
        tableRowClassName({row}) {
        if (row.StocFaptic != row.StocScriptic) {
          return 'warning-row';
        }
        return '';
      },
        show_me: async function (id) {
            await this.get_info();
            // this.selectedObject = JSON.parse(JSON.stringify(this.selectedObjectTemplate));
            this.selectedObject = this.selectedObjectTemplate;
            // this.produs         = JSON.parse(JSON.stringify(this.produsTemplate))
            this.showPopup = true;
            if (id == null) {
                this.mode = "add";
                this.selectedObject.NrDoc = this.Info.NrDoc;
                this.selectedObject.Produse = [];

                this.$nextTick(()=> {
                    this.$refs['focusMe'].focus();
                })

            } else {
                this.mode = "edit";
                var result = await this.post("inventar/get_info_item_dialog", { id: id });
                this.selectedObject = this.selectedObjectTemplate;
                this.selectedObject.Id = result.Item.Id;
                this.selectedObject.NrDoc = result.Item.NrDoc;
                this.selectedObject.Data = result.Item.Data;
                this.selectedObject.IdGestiune = result.Item.IdGestiune;
                this.selectedObject.Produse = result.Item.produse;
                // this.handleSelectGestiune();
            }
        },
        async get_info() {
            var response = await this.post("inventar/get_info_for_dialog");
            this.Info.gestiuni = response.gestiuni;
            this.Info.produse = response.produse;
            // this.Info.produseToateGestiunile = response.produseToateGestiunile;

            this.Info.NrDoc = response.lastNrDoc + 1;
        },        
        async precompleteaza() {
            this.selectedObject.Produse.forEach((el) => {
                el.StocFaptic = el.StocScriptic
            });
        },
        async handleSelectGestiune() {
            // this.Info.ProdusePtSelect = this.Info.produseToateGestiunile[this.selectedObject.IdGestiune]
            // this.selectedObject.Produse.splice(this.selectedObject.Produse.length)
            var response = await this.post("inventar/get_produse_by_gestiune", { idGestiune: this.selectedObject.IdGestiune })
            this.selectedObject.Produse = response.produse
        },

        save: async function () {
            this.$refs["my-form"].validate(async (valid) => {
                if (valid) {
                    let response = await this.post("inventar/save", {
                        mode: this.mode,
                        object: JSON.stringify(this.selectedObject),
                    });

                    if (response.Success){
                        this.$swal({
                        toast: true,
                        icon: 'success',
                        title: 'Inventarul a fost salvat',
                        showConfirmButton: false,
                        timer: 4500,
                        showCloseButton: true,
                        timerProgressBar: true,
                        width: 400,
                        });
                        this.resetForm();
                        this.$emit("save");
                    } else {
                        this.$notify({
                        title: "Eroare la salvare",
                        message: 'A intervenit o eroar!',
                        dangerouslyUseHTMLString: true,
                        type: "warning",
                        duration: 5000,
                        })
                        this.Eroare=true;
                        this.selectedObject.Produse = response.Produse;
                        this.handleSelectGestiune();
                        

                    }







                } else console.log("eroare validare formular!");
            });
        },
        resetForm() {
            this.selectedObject.Produse = []
            this.$refs["my-form"].resetFields();
            this.showPopup = false;
        },
        focusChanged (event) {
            const allElements = document.querySelectorAll('*');
            allElements.forEach((element) => {
                element.classList.remove('selectat');
            });

            const el = event.target
            el.classList.add("selectat");
         }
    },
    mounted: function () {
        this.get_info();
    },
    created() {
        document.addEventListener('focusin', this.focusChanged)
    },
    beforeDestroy() {
        document.removeEventListener('focusin', this.focusChanged)
    },

};
</script>
  
<style lang="less" scoped>
 .warning-row {
    background: oldlace !important;
    border: #40a0ffa1 !important;
    height: 9999px;
  }

  .el-table .success-row {
    background: #f0f9eb;
  }


#produs_cu_stoc:after {
    content: attr(name);
    width: auto;
    max-height: 27px !important;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
    font-size: 10px !important;
    padding: 0 5px;
    margin: 2px 0;
    border: 1px solid transparent;
    border-radius: 7px;
    background-color: #40a0ffa1;
}

/deep/ .el-input-number .el-input__inner {
    text-align: right;
}

/deep/ .select_dreapta .el-input__inner {
    text-align: right;
}

.full-width {
    width: 100% !important;

    .el-input {
        width: 85% !important;
    }

    .el-select {
        width: 85% !important;
    }

    .el-date-editor.el-input {
        width: 100% !important;
    }

    .btn-a {
        margin: 0;
        padding: 0;
        width: 15% !important;

        .el-button {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.el-table .el-form-item {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}

.tabel-valori {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.tabel-data {
    width: 300px !important;
    word-break: keep-all;

}

/deep/ .el-checkbox__input.is-focus{
  border: 3px solid yellow !important;
  border-radius: 5px;
}
/deep/ .selectat{
  background-color: yellow !important;
}

@media only screen and (max-width: 768px) {
    .tabel-data {
        width: 75% !important;
        font-size: 14px;
    }
}

.el-row {
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }
}
</style>