<template>
  <el-dialog title="Bon consum" :visible.sync="showPopup" :fullscreen="true" append-to-body :show-close="false" :close-on-press-escape="false">
    <el-form label-position="top" :inline="false" :rules="rules" label-width="100%" :model="selectedObject"
      ref="my-form" @submit.prevent="save" v-loading="loadingVisible" size="mini"
    >
    <!-- <pre>{{this.selectedObject}}</pre> -->
      <el-row :gutter="15">
        <el-col :md="5">
          <el-form-item label="Gestiune" prop="IdGestiune" size="mini">       
              <el-select size="mini" class="full-width" v-model="selectedObject.IdGestiune" @change="handleSelectGestiune()" filterable >
                <el-option v-for="item in Info.gestiuni" :key="item.Id + item.Nume" :label="item.Nume" :value="item.Id" />
              </el-select>
          </el-form-item>
          </el-col>
          <el-col :md='5'>
            <el-form-item label='Nr.' prop="Numar">
                <el-input  class='full-width' v-model='selectedObject.Numar' />
            </el-form-item>
          </el-col>
          <el-col :md="5">
          <el-form-item label="Data emitere" prop="DataBonConsum" size="mini">
            <div class="full-width">
              <el-date-picker v-model="selectedObject.DataBonConsum" type="date" value-format="yyyy-MM-dd" format="dd.MM.yyyy"  @change="handleSelectData" />
            </div>
          </el-form-item>
        </el-col>
        <!-- <el-col :md="4">
          <el-form-item label="Curs">
            <div class="full-width input-group">
              <el-input-number
                size="mini"
                v-model="selectedObject.Curs"
                :precision="4"
                controls-position="right"
                :step="0.01"
                @change="handleChangeCurs()"
              />
              <div class="btn-a input-group-append">
                <el-button style="margin-right: 16px" plain size="mini" type="primary" icon="el-icon-cloudy" @click="selecteazaDataCurs()"></el-button>
                <el-date-picker 
                :picker-options="datePickerOptions"
                popper-class="my-popover george"
                style="display: none" 
                ref="refDataCurs"  
                v-model="selectedObject.DataCurs" type="date" value-format="yyyy-MM-dd" format="dd.MM.yyyy" />
              </div>
            </div>
          </el-form-item>
        </el-col> -->
      </el-row>
      <el-row>
        <el-col :md="24">
          <el-card shadow="hover">
            <div slot="header" class="clearfix" style="padding: 0px">
              <span>Produse</span>
            </div>
            <el-table :header-cell-style="{ background: '#393E46' }" class="produse" :data="selectedObject.Produse" :key="refreshTable" >
              <el-table-column min-width="200px" label="Produs">
                <template slot-scope="scope">
                  <!-- <div v-if="!scope.row.Edited">{{ scope.row.NumeProdus }}</div>
                  <div v-else> -->
                    <el-form-item
                      :prop="'Produse[' + scope.$index + '].NumeProdus'"
                      :rules="{ required: true,  message: 'Campul este obligatoriu', trigger: 'blur', }"
                    >
                        <el-select class="full-width"
                          size="mini"
                          v-model="selectedObject.Produse[scope.$index].IdProdus"
                          @change="handleSelectProdus(scope.$index)"
                          filterable
                        >
                          <el-option
                            v-for="item in Info.ProdusePtSelect"
                            :key="item.Id"
                            :id="selectedObject.IdGestiune == '' ||
                            selectedObject.IdGestiune == null ||
                            selectedObject.IdGestiune == undefined ? '' : 'produs_cu_stoc'"
                            :name="'Stoc: ' + (item.Intrari - item.Iesiri)"
                            :label="item.Nume"
                            :value="item.Id"
                          >
                          </el-option>
                        </el-select>
                    </el-form-item>
                  <!-- </div> -->
                </template>
              </el-table-column>
              <el-table-column min-width="130px" label="Cantitate" style="text-align: center;">
                <template slot-scope="scope">
                  <div >
                    <el-form-item
                      :prop="'Produse[' + scope.$index + '].Cantitate'"
                      :rules="{ required: true, message: 'Campul este obligatoriu', trigger: 'blur',}"
                    >
                      <el-input-number size="mini" class="full-width" v-model="selectedObject.Produse[scope.$index].Cantitate" :precision="0"
                        :min="1"
                        :step="1"
                        @change="totalProduse()"
                      />
                    </el-form-item>
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="130px" label="Observatii" style="text-align: center;">
                <template slot-scope="scope">
                  <div >
                    <el-form-item
                      :prop="'Produse[' + scope.$index + '].Descriere'"
                      :rules="{ required: false, message: 'Campul este obligatoriu', trigger: 'blur',}"
                    >
                      <el-input size="mini" class="full-width" v-model="selectedObject.Produse[scope.$index].Descriere" />
                    </el-form-item>
                  </div>
                </template>
              </el-table-column>
              <!-- <el-table-column min-width="130px" label="P.U." style="text-align: center;">
                <template slot-scope="scope">
                    <el-form-item
                      :prop="'Produse[' + scope.$index + '].PretFaraTVA'"
                      :rules="{ required: true,  message: 'Campul este obligatoriu', trigger: 'blur', }"
                    >
                      <el-input-number
                        size="mini"
                        class="full-width"
                        v-model="selectedObject.Produse[scope.$index].PretFaraTVA"
                        :precision="2"
                        controls-position="right"
                        :step="0.01"
                        @change="totalProduse()"
                      />
                    </el-form-item>
                </template>
              </el-table-column>  -->
              <!-- <el-table-column label="P.U. Eur" style="text-align: center;">
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'Produse[' + scope.$index + '].PretFaraTVAEur'"
                    :rules="{ required: true,  message: 'Campul este obligatoriu', trigger: 'blur', }"
                  >
                    <el-input-number
                      size="mini"
                      class="full-width"
                      v-model="selectedObject.Produse[scope.$index].PretFaraTVAEur"
                      :precision="4"
                      controls-position="right"
                      :step="0.0001"
                      @change="handlePUEur(scope.$index)"
                    />
                  </el-form-item>
                </template>
              </el-table-column> -->
              <!-- <el-table-column min-width="100px" label="Cota TVA" style="text-align: center;">
                <template slot-scope="scope">
                    <el-form-item
                      :prop="'Produse[' + scope.$index + '].IdTVA'"
                      :rules="{ required: true,  message: 'Campul este obligatoriu', trigger: 'blur', }"
                    >
                      <el-select size="mini" class="full-width select_dreapta" v-model="selectedObject.Produse[scope.$index].IdTVA" @change="handleSelectTVA(scope.$index)">
                        <el-option
                          v-for="item in Info.listaTVA"
                          :key="item.Id"
                          :label="item.Procent"
                          :value="item.Id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                </template>
              </el-table-column> -->
              <el-table-column style="text-align: center;" fixed="right" label="Actiuni" width="100px">
                <template slot-scope="scope">
                  <span>
                    <!-- <el-tooltip content="Modifica">
                      <el-button v-if="!scope.row.Edited" size="mini" type="primary" icon="el-icon-edit" circle @click="modifica(scope.$index)" />
                      <el-button v-else size="mini" type="primary" icon="el-icon-check" circle @click="doneModifica(scope.$index)"/>
                    </el-tooltip> -->
                    <el-tooltip content="Sterge">
                      <el-button size="mini" type="danger" icon="el-icon-delete" circle @click="sterge(scope.$index)"/>
                    </el-tooltip>
                  </span>
                </template>
              </el-table-column>
            </el-table>
            <el-button @click="rand_nou()"> + </el-button>
            <!-- <el-row v-if="selectedObject.arata_total == true"> -->
              <!-- <el-col class="tabel-valori">
                <table cellpadding="10" class="tabel-data" style="margin-top: 10px; width: 70%">
                  <tr class="cell-data">
                    <th>Valoare totala fara TVA</th>
                    <td style="text-align: right;"> {{ selectedObject.ValoareFaraTVA | format_money }}</td>
                  </tr> -->
                  <!-- <tr class="cell-data">
                    <th>Valoare TVA</th>
                    <td style="text-align: right;"> {{ selectedObject.ValoareTVA | format_money }} </td>
                  </tr>
                  <tr class="cell-data">
                    <th>Valoare totala cu TVA</th>
                    <td style="text-align: right;"> {{ selectedObject.ValoareCuTVA | format_money }} </td>
                  </tr> -->
                <!-- </table>
              </el-col> -->
            <!-- </el-row> -->
          </el-card>
        </el-col>
      </el-row>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="resetForm('my-form')"> Renunta </el-button>
      <el-button type="primary" @click="save('my-form')"> Salveaza </el-button>
    </span>
    <!-- <pre>{{ this.selectedObject }}</pre> -->
  </el-dialog>
</template>

<script>
import moment from "moment";
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Produse_dialog from "@/pages/produse/Produse_dialog.vue";

export default {
  name: "Bonuri_consum_dialog",
  extends: BasePage,
  components: {
    "Produse-dialog": Produse_dialog,

  },
  data() {
    return {
      datePickerOptions: {
        disabledDate (date) {
            return date > new Date()
        }, 
      },
      refreshTable: 0,
      showPopup: false,
      mode: "add",
      selectedObject: {},
      selectedObjectTemplate: {
        Internet: "0",
        DataBonConsum: "",
        IdSerie: "",
        Numar: "",
        // Curs: "1",
        IdGestiune: "",
        Produse: [],
        Produse: [],
        arata_total: false,
      },
      produsTemplate: {
        IdProdus: "",
        NumeProdus: "",
        Cantitate: "",
        // PretFaraTVA: "",
        // PretFaraTVAEur: "",
        // ValoareFaraTVA: "",
      },
      Info: {
        listaTVA: [],
        gestiuni: [],
        produse: [],
      },
      rules: {
        Numar: [{ required: true, message: "Campul este obligatoriu" }],
        // IdSerie: [{ required: true, message: "Campul este obligatoriu" }],
        IdGestiune: [{ required: true, message: "Campul este obligatoriu" }],
        DataBonConsum: [{ required: true, message: "Campul este obligatoriu" }],
        // PretFaraTVA: [{ required: true, message: "Campul este obligatoriu" }],
        // Gestiune: [{ required: true, message: "Campul este obligatoriu" }],
        IdProdus: [{ required: true, message: "Campul este obligatoriu" }],
        Cantitate: [{ required: true, message: "Campul este obligatoriu" }],
      },
    };
  },
  // watch: {
  //   'selectedObject.DataCurs'(newselectedObject, oldselectedObject) {
  //     if(typeof oldselectedObject !== 'undefined' && this.selectedObject.DataCurs != null){
  //       this.getCurs(newselectedObject)
  //     }
  //   }
  // },
  methods: {
    show_me: async function (id) {
      this.selectedObject.Produse = [];
      await this.get_info();
      this.selectedObject = this.selectedObjectTemplate;
      this.showPopup = true;
      if (id == null) {
        this.mode = "add";
      } else {
        this.mode = "edit";
        var result = await this.post("bonuri_consum/get_info_item_dialog", { id: id });
        this.selectedObject = result.Item;
        this.handleSelectGestiune();
        // this.totalProduse();
      }
      this.minim1Produs();
    },
    
    async handleSelectData() {

      var result = await this.post("luni_blocate/verifica_data_intrare", { data: this.selectedObject.DataBonConsum });
      if(result.StatusLuna == 0){
        var data =  moment(this.selectedObject.DataBonConsum);
        var mesaj =  'Luna ' + this.$options.filters.LunaLitere(data.format('M')) + ' ' +  data.format('Y') + ' este inchisa!';
        this.$notify({
            title: "Atentie",
            message: mesaj,
            dangerouslyUseHTMLString: true,
            type: "warning",
            duration: 5000,
          })

        this.selectedObject.DataBonConsum = '';
      }
    },
    async get_info() {
      var response = await this.post("bonuri_consum/get_info_for_dialog");
      // this.Info  = response;
      this.Info.listaTVA  = response.listaTVA;
      this.Info.gestiuni  = response.gestiuni;
      this.Info.produse   = response.produse;
      this.Info.produseToateGestiunile = response.produseToateGestiunile;
    },
    
    // selecteazaDataCurs() { 
    //   this.$refs['refDataCurs'].pickerVisible = true;
    // },
      
    // handleChangeCurs() { 
    //   delete this.selectedObject.DataCurs;
    //   this.totalProduse();
    // },
  
    // totalProduse() {

    //   if(this.selectedObject.Produse.length > 0){
    //     var TotalValoareTva = [];
    //     var TotalValoareFaraTva = [];
    //     this.selectedObject.Produse.forEach((el) => {

    //       el.ValoareFaraTVA = parseFloat(el.PretFaraTVA * el.Cantitate).toFixed(2);
    //       el.PretFaraTVAEur = el.PretFaraTVA / this.selectedObject.Curs

        
    //       TotalValoareFaraTva.push(parseFloat(el.ValoareFaraTVA).toFixed(2));
           
    //     });

    //     this.selectedObject.ValoareFaraTVA = TotalValoareFaraTva.reduce((a, b) =>  Number(a) + Number(b));
    //     this.selectedObject.arata_total = true;

    //     this.refreshTable++;
    //   }
    // },
    minim1Produs() {          
      if(this.selectedObject.Produse.length == 0){
        this.rand_nou()
      }
    },
    rand_nou() {
      // var tvaImplicit = this.Info.listaTVA.filter((item) => item.Implicit == 1)[0];
      let nou = {
        Id: "",
        Cantitate: 1,
        // IdTVA: tvaImplicit.Id,
        // ProcentTVA: parseFloat(tvaImplicit.Procent).toFixed(0) ,
      };
      // if(this.selectedObject.Produse.length == 0 || typeof this.Info.ProdusePtSelect == 'undefined' ){
      //   this.Info.ProdusePtSelect = [];
      // }

      // this.Info.ProdusePtSelect[this.selectedObject.Produse.length] = this.Info.produse;
      this.selectedObject.Produse.push(nou);
      this.refreshTable++;
    },
    async sterge(row) {
      this.$confirm(`Sunteti sigur ?`, "Warning", {
        type: "warning",
      })
        .then(async () => {
          this.selectedObject.Produse.splice(row, 1);
          // this.totalProduse();
          this.minim1Produs();
          this.refreshTable++;
        })
        .catch(() => {
          // this.$message({type: 'info', message: 'Stergere anulata'});
        });
    },
   
    // async handlePUEur(item) {
    //   this.selectedObject.Produse[item].PretFaraTVA = this.selectedObject.Produse[item].PretFaraTVAEur * this.selectedObject.Curs;
    //   this.totalProduse();
    // },
    handleSelectProdus(item) {
      var idProdusSelectat = this.selectedObject.Produse[item].IdProdus;
      if(typeof idProdusSelectat !== 'undefined'){
        let produs = this.Info.produse.filter((prod) => prod.Id == idProdusSelectat)[0];
        this.selectedObject.Produse[item].NumeProdus = produs.Nume;
        // this.selectedObject.Produse[item].PretFaraTVA = produs.Pret;
        // this.selectedObject.Produse[item].PretFaraTVAEur = this.selectedObject.Produse[item].PretFaraTVA / this.selectedObject.Curs
      }
      this.selectedObject.Produse.splice( this.selectedObject.Produse.length)
      // this.totalProduse();
    },

    // handleSelectTVA(item) {
    //   this.selectedObject.Produse[item].ProcentTVA = this.Info.listaTVA[this.selectedObject.Produse[item].IdTVA - 1].Procent;
    //   this.totalProduse();
    // },
    handleSelectGestiune() {
      this.Info.ProdusePtSelect = this.Info.produseToateGestiunile[this.selectedObject.IdGestiune]
      this.selectedObject.Produse.splice( this.selectedObject.Produse.length)
    },

    save: async function () {
      this.$refs["my-form"].validate(async (valid) => {
        if (valid) {
          await this.post("bonuri_consum/save", {
            mode: this.mode,
            object: this.selectedObject,
          });
          this.$emit("save");
          this.resetForm();
        } else console.log("eroare validare formular!");
      });
    },
    resetForm() {
      this.$refs["my-form"].resetFields();
      this.showPopup = false;
    },
  },
  mounted: function () {
    this.get_info(); 
  },

};
</script>

<style lang="less" scoped>

#produs_cu_stoc:after {
  content: attr(name);
  width: auto;
  max-height: 27px !important;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  font-size: 10px !important;
  padding: 0 5px;
  margin: 2px 0;
  border: 1px solid transparent;
  border-radius: 7px;
  background-color: #40a0ffa1;
}
/deep/ .el-input-number .el-input__inner{ text-align: right;}
/deep/ .select_dreapta .el-input__inner{ text-align: right;}
.full-width {
  width: 100% !important;
  .el-input {
    width: 85% !important;
  }
  .el-select {
    width: 85% !important;
  }

  .el-date-editor.el-input {
    width: 100% !important;
  }

  .btn-a {
    margin: 0;
    padding: 0;
    width: 15% !important;
    .el-button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.el-table .el-form-item {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.tabel-valori {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.tabel-data {
  width: 300px !important;
  word-break: keep-all;
  
}


@media only screen and (max-width: 768px) {
  .tabel-data {
    width: 75% !important;
    font-size: 14px;
  }
}

.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
</style>