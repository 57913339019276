<template>
  <el-dialog title="Transfer" :visible.sync="showPopup" :fullscreen="true" append-to-body>
    
     <!-- {{this.Info.ProduseExistentePtSelect.length}}+
      <div v-for="linie in this.Info.ProduseExistentePtSelect"> {{linie.Id}} - <pre>{{linie}}</pre></div>
      --------
      <div v-for="linie in this.Info.ProdusePtSelect"> {{linie}}</div> -->
    <el-form
      label-position="top"
      :inline="false"
      :rules="rules"
      label-width="100%"
      :model="selectedObject"
      ref="my-form"
      @submit.prevent="save"
      v-loading="loadingVisible"
      status-icon
      size="mini"
    >
      <el-row :gutter="15">
        <el-col :md="4">
          <el-form-item label="Data" prop="Data" size="mini">
            <div class="full-width">
              <el-date-picker
              @change="handleSelectData"
                v-model="selectedObject.Data"
                type="date"
                value-format="yyyy-MM-dd"
                format="dd.MM.yyyy"
              />
            </div>
          </el-form-item>
        </el-col>        
        
        <el-col :md="4">
          <el-form-item label="Nr. Doc" prop="Numar">
            <el-input
              class="full-width"
              v-model="selectedObject.Numar"
            />
          </el-form-item>
        </el-col>
        <el-col :md="4">
          <el-form-item label="Gestiune sursa" prop="IdGestiuneSursa">
           <el-select
            size="mini"
            class="full-width"
            v-model="selectedObject.IdGestiuneSursa"
            @change="handleSelectGestiuneSursa()"
            filterable
            >
            <el-option
            v-for="item in Info.gestiuni"
            :key="item.Id + item.Nume"
            :label="item.Nume"
            :value="item.Id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>        
      <el-col :md="4">
        <el-form-item label="Gestiune destinatie"  prop="IdGestiuneDestinatie">
          <el-select
          size="mini"
          class="full-width"
          v-model="selectedObject.IdGestiuneDestinatie"
          @change="handleSelectGestiuneDestinatie()"
          filterable
          >
            <el-option
              v-for="item in Info.gestiuni"
              :key="item.Id + item.Nume"
              :label="item.Nume"
              :value="item.Id"
            ></el-option>
          </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <el-card shadow="hover" v-show="selectedObject.IdGestiuneSursa > 0 && selectedObject.IdGestiuneDestinatie > 0">
            <div slot="header" class="clearfix" style="padding: 0px">
              <span>Produse</span>
            </div>
            <el-table
            :header-cell-style="{ background: '#393E46' }"
              class="produse"
              :data="selectedObject.Produse"
              :key="refreshTable"
            >
            <el-table-column v-if="this.Eroare == true" width="28px">
                <template slot-scope="scope">
                  <el-tooltip 
                    v-if="selectedObject.Produse[scope.$index].Eroare != '' " 
                    :content="selectedObject.Produse[scope.$index].Eroare" 
                    :tabindex="-1" 
                  > 
                    <el-button size="mini" type="danger" icon="el-icon-error" circle style="padding: 2px;"/>
                  </el-tooltip>                   
                
                    <el-button  v-else
                    :tabindex="-1"  size="mini" type="success" icon="el-icon-success" circle style="padding: 2px;"/>
                  
                </template>
              </el-table-column>
              <!-- <el-table-column min-width="200px" label="Produs">
                <template slot-scope="scope">
                    <el-form-item
                      :prop="'Produse[' + scope.$index + '].IdProdus'"
                      :rules="{
                        required: true,
                        message: 'Campul este obligatoriu',
                        trigger: 'blur',
                      }"
                    >
                      <div class="input-group full-width">
                        <el-select
                          size="mini"
                          v-model="selectedObject.Produse[scope.$index].IdProdus"
                          @change="handleSelectProdus(scope.$index)"
                          filterable
                        >
                          <el-option
                            v-for="item in Info.ProdusePtSelect"
                            :key="item.Id"
                            :id="selectedObject.IdGestiuneSursa == '' ||
                            selectedObject.IdGestiuneSursa == null ||
                            selectedObject.IdGestiuneSursa == undefined ? '' : 'produs_cu_stoc'"
                            :name="'Stoc: ' + (item.Intrari - item.Iesiri)"
                            :label="item.Nume"
                            :value="item.Id"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </el-form-item>
                </template>
              </el-table-column> -->
              <el-table-column min-width="190px" label="Produs">
                <template slot-scope="scope">
                  <el-form-item :prop="'Produse[' + scope.$index + '].NumeProdus'"
                    :rules="{ required: true,  message: 'Campul este obligatoriu', trigger: 'blur', }">
                    <div class="input-group full-width">
                      <!-- {{ Info.ProdusePtSelect }}
                      {{ Info.ProdusePtSelect.length }} -->
                      <el-select size="mini" class="produs-input" 
                        remote
                        :remote-method="handleFindProdus"
                        v-model="selectedObject.Produse[scope.$index].IdProdus"
                        @change="handleSelectProdus(scope.$index)" 
                        :ref="'focusProdus[' + scope.$index + ']'"
                        filterable>
                        <el-option v-for="item in Info.ProdusePtSelect" :key="item.Id" :id="selectedObject.IdGestiuneSursa == '' ||
                        selectedObject.IdGestiuneSursa == null ||
                        selectedObject.IdGestiuneSursa == undefined ? '' : 'produs_cu_stoc'"
                          :name="typeof item.StocFinal != 'undefined' ? 'Stoc: ' + (item.StocFinal) : ''" 
                          :label="item.Nume" 
                          :value="item.Id">
                        </el-option>
                      </el-select>
                      <div v-if="selectedObject.Produse[scope.$index].IdProdus != 0" class="btn-a input-group-append">
                        <el-button tabindex="-1" plain size="mini" type="success" icon="el-icon-plus"
                          @click="show_add_new_produs(scope.$index)" class="success"></el-button>
                      </div>
                    </div>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column min-width="130px" label="Cantitate" style="text-align: right;">
                <template slot-scope="scope">
                  <!-- <div v-if="!scope.row.Edited">{{ scope.row.Cantitate }}</div> -->
                  <div v-if="selectedObject.Produse[scope.$index].IdProdus == '0'">-</div>
                  <div v-else>
                    <el-form-item
                      :prop="'Produse[' + scope.$index + '].Cantitate'"
                      :rules="{ required: true, message: 'Campul este obligatoriu', trigger: 'blur',}"
                    >
                      <el-input-number
                        size="mini"
                        class="full-width"
                        v-model="selectedObject.Produse[scope.$index].Cantitate"
                        :precision="0"
                        controls-position="right"
                        :min="0"
                        :step="1"
                      />
                    </el-form-item>
                  </div>
                </template>
              </el-table-column>
              <el-table-column style="text-align: center;" fixed="right" label="Actiuni" width="100px">
                <template slot-scope="scope">
                  <span>
                    <el-tooltip content="Sterge">
                      <el-button
                        size="mini"
                        type="danger"
                        icon="el-icon-delete"
                        circle
                        @click="sterge(scope.$index)"
                      />
                    </el-tooltip>
                  </span>
                </template>
              </el-table-column>
            </el-table>
            <el-button @click="rand_nou()"> + </el-button>
          </el-card>
        </el-col>
      </el-row>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="resetForm('my-form')"> Renunta </el-button>
      <el-button type="primary" @click="save('my-form')"> Salveaza </el-button>
    </span>
    <!-- <pre>{{ this.selectedObject }}</pre> -->
  </el-dialog>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import moment from "moment";

export default {
  name: "Facturi_dialog",
  extends: BasePage,
  components: {
  },
  data() {
    return {
      Eroare: false,
      refreshTable: 0,
      showPopup: false,
      mode: "add",
      selectedObject: {},
      selectedObjectTemplate: {
        Data: "",
        Curs: "1",
        Numar: "",
        IdGestiuneSursa: "",
        IdGestiuneDestinatie: "",
        Produse: [],
      },
      produsTemplate: {
        IdProdus: "",
        NumeProdus: "",
        Cantitate: "",
      },
      Info: {
        gestiuni: [],
        produse: [],
        ProdusePtSelect: [],
        ProduseExistentePtSelect: [],
      },
      rules: {
        Numar: [{ required: true, message: "Campul este obligatoriu" }],
        Data: [{ required: true, message: "Campul este obligatoriu" }],
        IdGestiuneSursa: [{ required: true, message: "Campul este obligatoriu" }],
        IdGestiuneDestinatie: [{ required: true, message: "Campul este obligatoriu" }],
        IdProdus: [{ required: true, message: "Campul este obligatoriu" }],
        Cantitate: [{ required: true, message: "Campul este obligatoriu" }],
      },
    };
  },
  methods: {
    show_me: async function (id) {
      this.selectedObject.Produse = [];
      await this.get_info();
      // this.selectedObject = JSON.parse(JSON.stringify(this.selectedObjectTemplate));
      this.selectedObject = this.selectedObjectTemplate;
      // this.produs         = JSON.parse(JSON.stringify(this.produsTemplate))
      this.showPopup = true;
      if (id == null) {
        this.mode = "add";
      } else {
        this.mode = "edit";
        var result = await this.post("transferuri/get_info_item_dialog", { id: id });
        this.selectedObject = result.Item;
        this.handleSelectGestiuneSursa();
        await this.seteazaSelect();
      }
      this.minim1Produs();
    },
    async get_info() {
      var response = await this.post("transferuri/get_info_for_dialog");
      this.Info.gestiuni  = response.gestiuni;
      // this.Info.produse   = response.produse;
      // this.Info.produseToateGestiunile = response.produseToateGestiunile;
    },

    async seteazaSelect() {

      this.Info.ProduseExistentePtSelect = [];
      this.selectedObject.Produse.forEach((el) => {
        console.log(el)
          let produs_deja_in_select = this.Info.ProduseExistentePtSelect.filter((xxx) => xxx.Id == el.Id)[0];
          if (!this.Info.ProduseExistentePtSelect.includes(produs_deja_in_select) ){
            el.Nume = el.NumeProdus;
            el.Id = el.IdProdus;
            this.Info.ProduseExistentePtSelect.push(el);
          }
        });

      this.Info.ProdusePtSelect = this.Info.ProduseExistentePtSelect;
      this.Info.ProduseExistentePtSelect = [];
    },

    minim1Produs() {          
      if(this.selectedObject.Produse.length == 0){
        this.rand_nou()
      }
    },
    rand_nou() {
      let nou = {
        Id: "",
        Cantitate: 1,
      };
      this.selectedObject.Produse.push(nou);
      this.refreshTable++;
    },
    async sterge(row) {
      this.$confirm(`Sunteti sigur ?`, "Warning", {
        type: "warning",
      })
        .then(async () => {

          this.Info.ProduseExistentePtSelect.forEach((el, index) => {
            if(this.selectedObject.Produse[row].Id == el.Id){
             if(this.selectedObject.Produse.filter((prod) => prod.Id == el.Id).length == 1){ // sterg doar daca e produsul 1 singura data
              this.Info.ProduseExistentePtSelect.splice(index, 1); // sterg din ProduseExistentePtSelect
              this.Info.ProdusePtSelect = this.Info.ProdusePtSelect.filter((prod) => prod.Id != el.Id); // sterg din ProdusePtSelect
             }
            }
          })
          this.selectedObject.Produse.splice(row, 1);
          this.minim1Produs();
          this.refreshTable++;
        })
        .catch(() => {
          // this.$message({type: 'info', message: 'Stergere anulata'});
        });
    },

    async handleSelectData() {

      var result = await this.post("luni_blocate/verifica_data_intrare", { data: this.selectedObject.Data });
      if(result.StatusLuna == 0){
        var data =  moment(this.selectedObject.Data);
        var mesaj =  'Luna ' + this.$options.filters.LunaLitere(data.format('M')) + ' ' +  data.format('Y') + ' este inchisa!';
        this.$notify({
            title: "Atentie",
            message: mesaj,
            dangerouslyUseHTMLString: true,
            type: "warning",
            duration: 5000,
          })

        this.selectedObject.Data = '';
      }
    },
    async  handleFindProdus(data) {
      if (data !== '') {
        await new Promise(resolve => setTimeout(resolve, 200)); 
        this.Info.clienti = [];
        this.loading = true;

        var result = await this.post("produse/remoteFindProdus", { string: data,idGestiune: this.selectedObject.IdGestiuneSursa });
        if(typeof result.ProduseFiltrate !== 'undefined'){
          
          // this.Info.ProdusePtSelect o sa contina rezultatul cautarii + produsele deja alese  
          // this.Info.ProdusePtSelect = this.Info.ProduseExistentePtSelect.concat(result.ProduseFiltrate)

          this.Info.ProdusePtSelect = result.ProduseFiltrate;
          this.Info.ProduseExistentePtSelect.forEach((el, index) => {
            if(result.ProduseFiltrate.filter((yyy) => yyy.Id == el.Id).length == 0){
              this.Info.ProdusePtSelect.push(el)
            }
          })
        }

      }
    },

    handleSelectProdus(item) {
      var idProdusSelectat = this.selectedObject.Produse[item].IdProdus;
      if(idProdusSelectat !== "0"){
        let produs = this.Info.ProdusePtSelect.filter((prod) => prod.Id == idProdusSelectat)[0];
        this.selectedObject.Produse[item].NumeProdus = produs.Nume;
      // this.selectedObject.Produse[item].NumeProdus = this.Info.produse.filter((prod) => prod.Id == idProdusSelectat)[0].Nume;
     
        this.selectedObject.Produse[item].Id = this.selectedObject.Produse[item].IdProdus
        this.selectedObject.Produse[item].Nume = this.selectedObject.Produse[item].NumeProdus

        if(this.Info.ProduseExistentePtSelect.filter((rrr) => rrr.Id == idProdusSelectat).length == 0){
          this.Info.ProduseExistentePtSelect.push(produs);
        }
      }
    },

    handleSelectGestiuneSursa() {
      // this.Info.ProdusePtSelect = this.Info.produseToateGestiunile[this.selectedObject.IdGestiuneSursa]
      // this.selectedObject.Produse.splice( this.selectedObject.Produse.length)
      if(this.selectedObject.IdGestiuneSursa == this.selectedObject.IdGestiuneDestinatie){
        this.selectedObject.IdGestiuneDestinatie = "";
      }
      if(this.Info.ProdusePtSelect.length > 0){
        this.Info.ProdusePtSelect.forEach((el) => {
          delete el.StocFinal;
        })
      }
    },        
    
    handleSelectGestiuneDestinatie() {
      if(this.selectedObject.IdGestiuneSursa == this.selectedObject.IdGestiuneDestinatie){
        this.selectedObject.IdGestiuneSursa = "";
        // this.Info.ProdusePtSelect = this.Info.produseToateGestiunile[this.selectedObject.IdGestiuneSursa]
      }
    },    

    save: async function () {
      this.$refs["my-form"].validate(async (valid) => {
        if (valid) {
          let response = await this.post("transferuri/save", {
            mode: this.mode,
            object: this.selectedObject,
          });

          this.Eroare=false;
          if (response.Success){
            
            this.$swal({
              toast: true,
              icon: 'success',
              title: response.mesajSalvare,
              showConfirmButton: false,
              timer: 4500,
              showCloseButton: true,
              timerProgressBar: true,
              width: 400,
            });
            this.resetForm();
            this.$emit("save");
          } else {
            this.$notify({
              title: "Eroare la salvare",
              message: 'Verificati daca exista stoc suficient pentru toate produsele!',
              dangerouslyUseHTMLString: true,
              type: "warning",
              duration: 5000,
            })
            this.Eroare=true;
            this.selectedObject.Produse = response.Produse;
            this.handleSelectGestiuneSursa();
            

          }
        } else console.log("eroare validare formular!");
      });
    },
    resetForm() {
      this.$refs["my-form"].resetFields();
      this.showPopup = false;
    },
  },
  mounted: function () {
    this.get_info(); 
  },
};
</script>

<style lang="less" scoped>

#produs_cu_stoc:after {
  content: attr(name);
  width: auto;
  max-height: 27px !important;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  font-size: 10px !important;
  padding: 0 5px;
  margin: 2px 0;
  border: 1px solid transparent;
  border-radius: 7px;
  background-color: #40a0ffa1;
}
/deep/ .el-input-number .el-input__inner{ text-align: right;}
/deep/ .select_dreapta .el-input__inner{ text-align: right;}
.full-width {
  width: 100% !important;
  .el-input {
    width: 85% !important;
  }
  .el-select {
    width: 85% !important;
  }

  .el-date-editor.el-input {
    width: 100% !important;
  }

  .btn-a {
    margin: 0;
    padding: 0;
    width: 15% !important;
    .el-button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.el-table .el-form-item {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.tabel-valori {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.tabel-data {
  width: 300px !important;
  word-break: keep-all;
  
}


@media only screen and (max-width: 768px) {
  .tabel-data {
    width: 75% !important;
    font-size: 14px;
  }
}

.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
</style>